.headerContainer {
    width: 100%;
    padding: 24px 40px;
    position: fixed;
    top: 0;
    z-index: 99;
}

.headerWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 32px;
    background: rgba(63, 66, 55, 0.20);
    backdrop-filter: blur(50px);
}

.downloadButton {
    padding: 15px 28px;
    background: #875BF7;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase;
    border: 0;
    cursor: pointer;
}

@media screen and (max-width: 1080px) {
    .headerWrapper img {
        max-width: 204px;
    }
}

@media screen and (max-width: 744px) {
    .headerWrapper img {
        max-width: 143px;
    }

    .downloadButton {
        padding: 12px 24px;
        font-size: 10px;
    }

    .headerWrapper {
        padding: 16px 20px;
    }

    .headerContainer {
        padding: 24px 20px;
    }
}