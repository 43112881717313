.footerSection {
    width: 100%;
    border-radius: 59.875px 59.875px 0px 0px;
    border: 1px solid rgba(64, 70, 79, 0.68);
    background: #171717;
    padding: 60px 120px;
    overflow: hidden;
    position: relative;
}

.footerContainer {
    max-width: 550px;
    display: flex;
    align-content: center;
    justify-content: center;
    gap: 20px;
    justify-content: space-between;
    flex-direction: column;

    > hr {
        width: 100%;
        border-color: #475467;
    }
    z-index: 1;
}

.footerItemsWrapper {
    display: flex;
    flex-direction: row;
    flex: 1;
    gap: 40px;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 14.969px; /* 99.792% */
    z-index: 100;

    > * {
        color: white;
        margin: 0;
        margin-top: 6px;
        text-decoration: none;
    }
}

.footerItemTitle {
    color: #FFF;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    margin: 0;
}

.footerLogoWrapper {
    display: flex;
    height: 38px;
    align-items: center;
    gap: 20px;
    z-index: 100;
    > img {
        height: 100%;
    }
    > img:first-child {
        border-right: 1px solid white;
        padding-right: 20px;
    }

    > img:last-child {
        height: 16px;
    }
}

.footerLogo {
    position: absolute;
    top: -80px;
    right: 100px;
    transform: scale(2);
    z-index: 0; 
    opacity: 40%;
    z-index: 0;
}

@media screen and (max-width: 1275px) {
    .footerLogo {
        > img {
            opacity: 40%;
        }
    }
}

@media screen and (max-width: 1080px) {
    .footerWrapper {
        padding: 0;
        background-color: transparent;
        flex-wrap: wrap;
        gap: 40px;
    }

    .footerItemsWrapper {
        min-width: 130px;
    }
}

@media screen and (max-width: 700px) {
    .footerLogoWrapper {
        height: 26px;
    }

    .footerSection {
        padding: 40px 80px;
    }
}

@media screen and (max-width: 430px) {
    .footerSection {
        padding: 40px 60px;
    }
}