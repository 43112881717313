.heroActionBtnsRow {
    display: flex;
    gap: 16px;
    align-items: center;
    position: relative;
    justify-content: center;
}

.heroActionBtnsRow a {
    padding: 15px 28px;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase;
    cursor: pointer;
    color: #fff;
    max-width: 128px;
    width: 128px;
    z-index: 10;
    text-align: center;
    text-decoration: none;
}

.ovalPurpleHorizontalBlur {
    border-radius: 271px;
    opacity: 0.7;
    background: var(--violet-500, #875BF7);
    filter: blur(38px);
    height: 100%;
    width: 100%;
    position: absolute;
}

.heroDownloadBtn {
    background: #875BF7;
    border: 0;
}

.heroDocsBtn {
    background: transparent;
    border: 1px solid #FFF;
}


@media screen and (max-width: 500px) {
    .heroDocsBtn.hideIfMobile{
        display: none;
    }
}

.heroLightStar1 {
    position: absolute;
    right: -100px;
}

@media screen and (max-width: 440px) {
    .heroLightStar1 {
        right: -50px;
        top: -20px;
        width: 28px;
    }
}
