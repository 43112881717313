* {
  box-sizing: border-box;
  font-family: Inter;
}

@font-face {
  font-family: Inter;
  src: url("/public/fonts/Inter-Regular.ttf");
}

@font-face {
  font-family: Engine;
  src: url("/public/fonts/Engine.ttf");
}

body {
  background: #03090E;
  overflow-x: hidden;
}
