.heroWrapper {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 80px;
    padding-left: 40px;
}

.heroLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    > h1 {
        line-height: 72px;
        font-size: 48px;
    }
}

.heroCoverImg {
    min-width: 574px;
    height: auto;
    display: flex;
}

.heroTitle {
    color: #FFF;
    font-family: Engine;
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin: 0;
    margin-top: 20px;
}

.heroTitleSpan {
    color: #875BF7;
    font-family: Engine;
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin: 0;
}

.heroDescription {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    margin: 24px 0;
    color: #fff;
    opacity: 0.8;
}

.heroIllustration {
    position: absolute;
}



.heroRightWrapper {
    position: relative;
}

.heroFly {
    position: absolute;
    bottom: -142px;
    left: 110px;
}

.effortlessIntegrationSection {
    padding: 192px 40px 104px 0;
    display: flex;
    align-items: center;
    gap: 120px;
}

.effortlessCoverWrapper {
    position: relative;
    min-width: 535px;
    min-height: 497px;
    display: flex;
    align-items: center;
}

.lightEffect {
    position: absolute;
    top: 70px;
    left: 100px;
}

.effortlessDescWrapper {
    position: relative;
}

.effortlessDescWrapper .lightEffect  {
    bottom: -70px;
    left: 200px;
    top: auto;
}

.circularPurpleBlur {
    border-radius: 525.134px;
    opacity: 0.6;
    background: var(--base-brand, #875BF7);
    filter: blur(328.8999328613281px);
    height: 100%;
    width: 100%;
    position: absolute;
}



.sectionTitle {
    color: #FFFFFF;
    font-size: 40px;
    font-weight: 500;
    line-height: 54px;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 0;
    position: relative;
}

.openSourceSection .sectionTitle .lightEffect {
    right: -10px;
    top: -30px;
    left: auto;
}

.openSourceSection .sectionTitle .effect3 {
    position: absolute;
    right: -40px;
    bottom: -50px;
}

.openSourceSection .sectionTitle .effectRow {
    display: flex;
    align-items: flex-start;
    position: absolute;
    left: 0;
    bottom: -100px;
    gap: 20px;
}

.sectionTitle span {
    color: #875CF7;
}

.sectionDescription {
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    opacity: 0.8;
    margin: 0;
    margin-top: 24px;
}

.openSourceSection {
    width: 100%;
    display: flex;
    padding: 0 0 104px 40px;
    align-items: center;
    gap: 40px;
    justify-content: space-between;
}

.openSourceCoverWrapper {
    display: flex;
    align-items: center;
    position: relative;
    min-width: 567px;
    min-height: 473px;
}


.pluginSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.columnSectionTitle {
    color: #FFF;
    text-align: center;
    font-family: Engine;
    font-size: 55px;
    font-weight: 400;
    line-height: 68.8px;
    letter-spacing: 2.75px;
    text-transform: uppercase;
    margin: 0;
    text-align: center;
    position: relative;
    width: fit-content;
}

.columnSectionTitle .effectRow {
    position: absolute;
    bottom: 20px;
    left: 220px;
    display: flex;
    align-items: flex-start;
    gap: 14px;
}

.columnSectionTitle .effect7 {
    position: absolute;
    right: -30px;
    top: 20px;
}

.pluginImgsRow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow-x: hidden;
    gap: 40px;
    margin-top: 40px;
}

.developmentSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 104px;
    padding: 0 40px;
}

.developmentList {
    width: 100%;
    margin-top: 48px;
    gap: 40px;
    display: grid;
    grid-template-columns: repeat(2, 48%);
    align-items: center;
    justify-content: center;
}

.developmentCard {
    width: 100%;
    height: 100%;
    padding: 40px 32px;
    background: linear-gradient(0deg, rgba(113, 64, 237, 0.20) -16.95%, rgba(194, 170, 255, 0.00) 100%), #03090E;
}

.developmentCardTitle {
    color: #FFF;
    font-family: Inter;
    font-size: 25px;
    font-weight: 500;
    line-height: 31.25px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    margin: 0;
}

.developmentCardDesc {
    color: #FFF;
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    opacity: 0.8;
    margin: 0;
    margin-top: 14px;
}

.downloadSection {
    padding: 104px 40px;
    gap: 40px;
    display: grid;
    grid-template-columns: repeat(2, 48%);
    align-items: center;
    justify-content: center;
}

.downloadSection .columnSectionTitle {
    text-align: left;
}

.downloadSectionDesc {
    color: #FFF;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    opacity: 0.8;
    margin: 0;
}

.downloadActionRow {
    display: flex;
    gap: 16px;
    align-items: center;
    position: relative;
    margin-top: 14px;
    width: fit-content;
}

.downloadActionRow button {
    padding: 15px 28px;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase;
    cursor: pointer;
    color: #fff;
    min-width: 128px;
    z-index: 10;
}

.downloadActionRow {
    position: relative;
}

.downloadActionRow .effect8 {
    position: absolute;
    right: -100px;
    top: -10px;
}

.openSourceCover {
    margin: auto;
    max-width: 100%;
    padding: 10px 40px;
}

.openSourceCoverMobile, 
.openSourceEffectMobileLeft, 
.openSourceEffectMobileRight {
    display: none;
}

@media screen and (max-width: 1280px) {
    .heroTitle, .heroTitleSpan {
        font-size: 40px;
    }

    .columnSectionTitle {
        font-size: 40px;
        line-height: 60px
    }
}

@media screen and (max-width: 1080px) {
    .heroWrapper {
        flex-direction: column;
        padding-top: 60px;
        align-items: center;
    }

    .heroLeft {
        margin-right: auto;
    }

    .heroRightWrapper {
        margin-top: 90px;
        display: flex;
        justify-content: center;
    }

    .heroFly {
        left: auto;
    }

    .effortlessIntegrationSection {
        flex-direction: column;
        align-items: center;
        padding-right: 0;
    }

    .effortlessDescWrapper {
        padding: 0 40px;
    }

    .effortlessCoverWrapper {
        width: 100%;
    }

    .effortlessCoverWrapper .lightEffect {
        display: none;
    }

    .effortlessCover {
        display: none;
    }

    .effortlessDescWrapper .lightEffect {
        display: none;
    }

    .openSourceSection {
        flex-direction: column-reverse;
        padding: 0 40px 75px 40px;
    }

    .developmentList {
        display: block;
    }

    .downloadSection {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .columnSectionTitle br {
        display: none;
    }

    .hyperPlayMetaMaskWalletConnectImage {
        margin: auto;
        max-width: 100%;
        padding: 10px 40px;
    }

    .downloadSection .columnSectionTitle {
        text-align: left;
    }

    .pluginImgsRow img {
        height: 277px;
        width: auto;
    }

    .openSourceSection .sectionTitle .lightEffect, .effect3, .effectRow {
        display: none !important;
    }

    .openSourceEffectMobileLeft {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
    }

    .openSourceEffectMobileRight {
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .openSourceCoverWrapper {
        min-width: auto;
        min-height: auto;
        position: relative;
    }

    .downloadActionRow .effect8 {
        display: none;
    }
}

@media screen and (max-width: 868px) {
    .effortlessIntegrationSection {
        gap: 0;
    }
}

@media screen and (max-width: 744px) {
    .heroTitle, .heroTitleSpan {
        font-size: 24px;
    }

    .heroWrapper {
        padding-top: 40px;
    }

    .heroDescription {
        font-size: 16px;
    }

    .heroCoverImg {
        min-width: auto;
        width: 100%;
    }

    .heroFly {
        width: 41px;
        bottom: -105px;
    }

    .heroRightWrapper {
        margin-top: 50px;
    }

    .sectionTitle {
        font-size: 24px;
        line-height: 36px;
    }

    .sectionDescription {
        font-size: 16px;
        line-height: 24px;
    }

    .pluginSection .columnSectionTitle {
        padding: 0 40px;
    }

    .pluginImgsRow {
        gap: 10px;
    }

    .pluginImgsRow img {
        height: 139px;
    }

    .pluginSection .columnSectionTitle, .developmentSection .columnSectionTitle {
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 1.2px;
    }

    .developmentSection {
        margin-top: 80px;
    }

    .developmentList {
        margin-top: 40px;
    }

    .developmentCardTitle {
        font-size: 20px;
    }

    .developmentCardDesc {
        font-size: 16px;
        line-height: 24px;
    }

    .columnSectionTitle .effect7 {
        right: 120px;
        top: auto;
        margin-top: 20px;
        z-index: -1;
    }

    .downloadSection {
        padding: 80px 40px;
    }

    .downloadSection .columnSectionTitle {
        font-size: 24px;
        line-height: 36px;
    }
}

@media screen and (max-width: 620px) {

    .effortlessCoverWrapper {
        min-height: 360px;
    }

    .heroLeft {
        > h1 {
            line-height: 46px;
            font-size: 34px;
        }
    }
}

@media screen and (max-width: 530px) {

    .effortlessCoverWrapper {
        min-height: 300px;
        min-width: 430px;
    }
}

@media screen and (max-width: 440px) {
    .effortlessCoverWrapper {
        min-height: 230px;
    }
}

.landingPage {
    max-width: 1200px;
    margin: 0px auto;
}

.downloadPluginCTAContainer {
    margin-right: 40px;
    display: inline-block;
}

.carouselContainer {
    display: flex;
    position: relative;
    margin: 20px 0px;
}

.carouselSlide {
    position: relative;
    padding: 0px 10px;
    > div {
        width: 640px;
        height: 360px;
    }
}

.carouselIndicators {
    margin: 0px auto;
    display: flex;
    justify-content: center;
    gap: 4px;
    > button[data-active="true"]{
        background-color: #875BF7;
    }
    > button {
        border: 1px solid transparent;
        border-radius: 2px; 
        height: 4px;

        &:hover {
            cursor: pointer;
        }
    }
}

.carouselViewport {
    width: 1200px;
    overflow: hidden;
}


@media screen and (max-width: 700px) {
    .carouselIndicators {
        display: none;
    }

    .carouselContainer {
        flex-direction: column;
        max-width: 100vw;
        gap: 20px;
    }

    .carouselSlide {
        padding: 0px;
        margin: 0px auto;
        max-width: 100vw;
        width: 90vw;
        height: calc(90vw / 16 * 9);
        > div {
            width: 100%;
            height: 100%;
        }
    }
    
    .carouselViewport {
        max-width: 100vw;
    }
}